<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div v-show="permissionsLoading" style="height: 200px">
                <loading v-show="permissionsLoading" />
            </div>
            <div v-show="!permissionsLoading">
                <b-row>
                    <b-col>
                        <p>{{ $t('role_permissions') }}</p>
                        <ValidationProvider name="permissions" rules="required" v-slot="{valid,errors}">
                            <checkbox-with-filter v-model="formData.permissions"
                                                  :options="permissions"
                                                  :height="500"
                                                  :validate-error="errors[0] ? errors[0]:''"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-col>
                    <b-col>
                        <p>{{ $t('your_selected_permission') }}</p>
                        <div class="selected-area">
                            <div class="" v-if="filterSelectPermission && filterSelectPermission.length >0">
                                <ol>
                                    <li v-for="selectedPermission in filterSelectPermission">
                                        {{ selectedPermission.name }}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-center mt-5">
                    <b-button variant="primary"
                              @click="updateForm"
                              :disabled="formLoading">
                        {{ $t('save') }}
                    </b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<style>
    .selected-area {
        border: 1px solid #e9ecef;
        border-radius: .375rem;
        height: 500px;
        padding: 15px;
        overflow-x: auto;
    }
</style>
<script>
    // Component
    import CheckboxWithFilter from '@/components/elements/CheckboxWithFilter';
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Loading from '@/components/elements/Loading'

    // Services
    import PermissionService from '@/services/PermissionService';

    export default {
        components: {
            CheckboxWithFilter,
            ValidationProvider,
            ValidationObserver,
            Loading
        },
        props: {
            formId: {}
        },
        data() {
            return {
                permissionsLoading: false,
                permissions: [],

                formLoading: false,
                formData: {
                    permissions: []
                }
            }
        },
        computed: {
            filterSelectPermission() {
                return this.permissions.filter(item => {
                    return this.formData.permissions.includes(item.id);
                })
            }
        },
        methods: {
            async getPermissions() {
                this.permissionsLoading = true;
                await PermissionService.getAll()
                                       .then((response) => {
                                           let datas = response.data.data;
                                           this.permissions = [];
                                           datas.forEach((item) => {
                                               this.permissions.push({
                                                   ...item,
                                                   slug: item.name,
                                                   name: this.$t(item.name)
                                               })
                                           });
                                       })
                                       .catch(e => {
                                           this.showErrors(e);
                                       })
                                       .finally(()=>{
                                           this.permissionsLoading = false;
                                       })
            },
            async getRolePermissions() {
                await PermissionService.get(this.formId)
                                       .then(response => {
                                           let permissions = response.data.data.permissions;
                                           this.formData.permissions = permissions.map(item => {
                                               return item.id;
                                           })
                                       })
                                       .catch(e => {
                                           this.showErrors(e);
                                       })
            },

            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    PermissionService.update(this.formId, this.formData)
                                     .then(response => {
                                         this.$emit('permissionFormSuccess', true);
                                         this.$toast.success(this.$t('api.' + response.data.message));
                                     })
                                     .catch(e => {
                                         this.showErrors(e, this.$refs.formModalValidate);
                                     })
                                     .finally(() => {
                                         this.formLoading = false
                                     })
                }
            }
        },
        created() {
            this.getPermissions()
            this.getRolePermissions()
        }
    }
</script>
