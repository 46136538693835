<template>
    <div>
        <label v-if="name">{{ name }}</label>
        <div class="border rounded-sm p-3"
             :class="validateError != '' ? 'checkbox-area-error':''"
        >
            <label v-if="filterArea">{{ $t('search') }}</label>
            <b-input v-model="filterInput"
                     class="mb-2"
                     v-if="filterArea"
            />
            <div class="checkbox-area"
                 :style="{height: computeHeight }">
                <b-form-group>
                    <b-form-checkbox-group v-model="selected"
                                           :options="computeOptions"
                                           name="values"
                                           value-field="id"
                                           text-field="name"
                                           @change="handleInput"
                                           stacked
                    >
                    </b-form-checkbox-group>
                </b-form-group>
            </div>
        </div>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
    export default {
        props: {
            options: {
                type: Array,
                default: []
            },
            value: {
                type: Array,
                default: []
            },
            name: {
                type: String,
                default: null
            },
            validateError: {
                type: String,
                default: ''
            },
            filterArea:{
                type: Boolean,
                default: true
            },
            height: {
                type: Number,
                default: 310
            }
        },
        data() {
            return {
                selected: this.value,
                filterInput: null
            }
        },
        computed: {
            computeOptions() {
                if (this.filterInput) {
                    if (this.options) {
                        return this.options.filter(item => {
                            return item.name.toLowerCase().indexOf(this.filterInput.toLowerCase()) > -1
                        })
                    }
                    return [];
                }
                else {
                    return this.options;
                }
            },
            computeHeight() {
                let height = this.height - 110;
                return height + 'px';
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = newValue
            }
        },
        methods: {
            handleInput(event) {
                this.$emit('input', event && event.length>0 ? event.sort():[])
            }
        }
    };
</script>
<style>
    .checkbox-area {
        border: 1px solid #e9ecef;
        border-radius: .375rem;
        height: 310px;
        padding: 15px;
        overflow-x: auto;
    }

    .checkbox-area-error {
        border-color: #f696a8 !important;
    }
</style>
